import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3441252649/src/src/templates/faq/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Transaction data`}</h2>
    <h3>{`What transaction data do I receive from Fidel? What is the frequency and method  of receiving transaction data?`}</h3>
    <h4>{`If you are using our ‘Select Product’:`}</h4>
    <p>{`Please see more details on what Transaction data you will receive for each transactions  event as well as the transaction lifecycle here: `}<a parentName="p" {...{
        "href": "https://fidelapi.com/docs/select/transactions"
      }}>{`Transctions Docs`}</a>{`.`}</p>
    <h4>{`If you are using our ‘Offers as a Service (OaaS)’ product as a Content Provider:`}</h4>
    <p>{`Fidel receive the verified transaction data from OaaS publishers in batch (daily/ weekly/ monthly), we have a defined format and fields which our Publishers send the transaction data as, please see those here: `}<a parentName="p" {...{
        "href": "https://fidel-oaas.readme.io/reference/publisher-to-fidel"
      }}>{`Fidel API OaaS Readme`}</a>{`.`}</p>
    <p>{`You will be able to get transaction reporting on your offers via our Data Analytics tools called Metabase, where you can view but also download reports from. Please connect with your Fidel Business Development Lead on any more details here.`}</p>
    <h2>{`Missing transactions`}</h2>
    <h3>{`A cardholder has reported a missing transaction to me, how can I get this investigated?`}</h3>
    <p>{`Please raise a support ticket by contacting either of the below:`}</p>
    <ul>
      <li parentName="ul">{`Email `}<a parentName="li" {...{
          "href": "mailto:support@fidel.uk"
        }}>{`support@fidel.uk`}</a></li>
      <li parentName="ul">{`Raise a raise request at `}<a parentName="li" {...{
          "href": "https://fidellimited.zendesk.com"
        }}>{`Zendesk`}</a></li>
    </ul>
    <p>{`Please use below template:`}</p>
    <pre><code parentName="pre" {...{}}>{`Hi Fidel team, can you please find the MID for the following transaction:

Location ID:
Card ID:
Transaction amount (with tip, don’t include currency symbol):
Transaction date (without time - YYYY-MM-DD):
Card scheme/network (visa, mastercard, amex):

Many thanks,
XXX
`}</code></pre>
    <h2>{`Refunds/ Reversals`}</h2>
    <h4>{`How does Fidel share refunded/ reversed transactions?`}</h4>
    <p>{`Please see more information on refunds here: `}<a parentName="p" {...{
        "href": "https://fidelapi.com/docs/select/transactions/#refund"
      }}>{`Fidel API - Refund`}</a></p>
    <h3>{`How do I calculate how much cashback/reward to debit from the cardholder in the event of a partial refund?`}</h3>
    <p>{`In many scenarios a reward is calculated based on a pre-determined percentage against the sale value.`}</p>
    <p>{`Whilst we can track refunds and communicate these events (through the `}<code>{`transaction.refund`}</code>{`/`}<code>{`transaction.refund.qualified`}</code>{` webhook events) which includes the `}<code>{`originalTransactionId`}</code>{` (`}<a parentName="p" {...{
        "href": "https://fidelapi.com/docs/select/transactions/#refund"
      }}>{`Fidel API - Refund`}</a>{`), Fidel won’t know if a refund event shared with you was a ‘partial’ refund, however you will receive the `}<code>{`originalTransactionId`}</code>{` as part of the refund, which is the reference to the original transaction.`}</p>
    <p>{`You will also receive (amongst others) the below data points:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Card ID (The user who made the refund)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Amount`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Merchant`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Date`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cashback Amount (null as transaction is NOT qualified)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Offer ID`}</p>
      </li>
    </ul>
    <p>{`This information allows you to call the cardholders transaction history within their own database.`}</p>
    <p>{`In order for you to calculate the cashback value from the refund based on the offer id, you can use our Offer API. More specifically, you can use `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/get-offer"
      }}>{`this`}</a>{` endpoint in order to retrieve the value.`}</p>
    <h2>{`Only clearing or auth received`}</h2>
    <h3>{`Why has a transaction only been tracked in clearing or vice versa?`}</h3>
    <p>{`There are a number of reasons why this may occur but some typical reasons are as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Some acquirers will provide different MIDs for either/or the clearing and auth event of a transaction. Potentially one of these MIDs has been missed, if that is the case Fidel would not be able to track the relevant event;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Transaction was performed at a ‘Shared MID’, please see more on this here: `}<a parentName="p" {...{
            "href": "http://fidelapi.com/faq/locations-mo/#shared-mids"
          }}>{`Locations FAQ`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Particularly in the case of auth, some retailers forgo this event completely as these events come at cost;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Transaction was a ‘offline’ transaction and hence only the clearing was passed by the card network;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Transaction was performed on a ‘co-badge’ card and processed on a domestic scheme.`}</p>
      </li>
    </ul>
    <h4>{`‘Offline’ transaction:`}</h4>
    <p>{`Offline card payments transactions refer to when a customer uses their credit or debit card to make a purchase, but the transaction is not immediately authorized by the Issuer(Bank). Instead, the transaction is processed through a clearing system, where the merchant sends the transaction details to the bank for approval and settlement at a later time.`}</p>
    <p>{`The reason there isn't an immediate authorization for offline transactions is because they typically occur in situations where the card cannot be verified in real-time, such as when the card reader is not connected to the internet (such as on planes, or on trains etc.). In these cases, the transaction is processed offline and the authorization is obtained through a clearing process later on. This allows the transaction to still go through even without immediate verification.`}</p>
    <p>{`It could also be that the merchant has an agreed ‘floor-limit’, which is the maximum amount that a merchant will allow for offline transactions to be processed without requiring authorization from the card issuer. This is typically set by the merchant's acquiring bank and is meant to reduce the risk of fraud and chargebacks but also to reduce queuing or waiting time to process time (i.e. at Coffee shops, train ticket vendors/ vending machines etc.).`}</p>
    <p>{`For example, if a merchant has a floor limit of $50, any offline transaction below that amount can be processed without needing to contact the card issuer for authorization.`}</p>
    <h4>{`Co-badge card transactions:`}</h4>
    <p>{`When a transaction is processed on a domestic scheme, such as a co-badge card transaction, it means that the transaction is being processed through a local payment network (i.e. UAESWITCH, Giro etc.) rather than a global card network like Visa or Mastercard. In these cases, the transaction may not require authorization from the card network because it is being processed within the same country or region where the card was issued.`}</p>
    <p>{`However, even though the transaction may not require authorization from the card network, it will still go through a clearing.`}</p>
    <p>{`So, while co-badge card transactions may not require authorization from the card network, they will still go through a clearing.`}</p>
    <h2>{`Apple Pay/Google pay transactions`}</h2>
    <h3>{`Can Fidel track transactions made through a digital wallet such as Google Pay or Apple Pay?`}</h3>
    <p>{`Fidel can track spend through digital wallets as long as the `}<b>{`full PAN from the front of the card is enrolled`}</b>{`. This is particularly important to note here as a digital wallet will tokenize the card number as a security measure and this is usually accessible to the cardholder.`}</p>
    <p>{`If the cardholder were to enrol the virtual PAN (PAR), then Fidel would not be able to track that person’s spend. The onus is on the client to manage the messaging to their users around this topic.`}</p>
    <h2>{`VOIDs`}</h2>
    <h2>{`Does Fidel track VOIDs?`}</h2>
    <ul>
      <li parentName="ul">
        <b>Amex</b> - No.
      </li>
      <li parentName="ul">
        <b>Mastercard</b> - Yes. Mastercard sends files periodically with VOIDs.
      </li>
      <li parentName="ul">
        <b>Visa</b> - Yes. It is sent in the same way of normal refunds.
      </li>
    </ul>
    <p>{`For the supported networks, Fidel sends the VOID to the publisher through the normal refund webhook so that publishers can then decide which process and business logic should be in place on their end.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      